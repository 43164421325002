import React from 'react';
import { FaLinkedin } from 'react-icons/fa';
import logo from '../assets/images/logo.png';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-primary text-WhiteLight py-6 px-4 sm:px-8 mt-24">
      <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center border-WhiteLight pt-4 border-t-2">
        <div className="flex items-center">
          <img src={logo} alt="ARK Group Logo" className="w-36 h-36 mr-2" />
          <span className="text-RedLight text-xl font-bold">ARK Group of Companies</span>
        </div>
        <div className="flex items-center mt-4 sm:mt-0">
          <div>
            <p className="text-lg">
              <span className="font-bold text-RedLight">Email <br /></span> {' '}
              <a href="mailto:info@arkgroup.pk" className="text-WhiteLight">info@arkgroup.pk</a>
            </p>
            <p className="text-lg">
              <span className="font-bold text-RedLight ">Phone <br /></span> {' '}
              <a href="tel:+923336999944" className="text-WhiteLight">+92 333 699 9944</a>
            </p>
            <p className="text-lg">
              <span className="font-bold text-RedLight">Address <br /></span> {' '}
              90/B Gulberg Green Service Road Batti Town Lane 8 Islamabad, Pakistan
            </p>
            <a href="https://www.linkedin.com/company/a-r-k-group/" target="_blank" rel="noopener noreferrer" className="text-RedLight hover:text-WhiteLight mx-auto block w-fit sm:mx-0 mt-6">
              <FaLinkedin className="text-3xl" />
            </a>
          </div>
        </div>
      </div>
      <div className="text-center text-WhiteLight mb-0 mt-10">
        Copy Right - ARK Group of Companies &reg; - {currentYear}
      </div>
    </footer>
  );
};

export default Footer;
