import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom'; // Assuming you're using React Router for navigation

const Error = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen  text-WhiteLight">
      <Helmet>
        <title>Page Not Found - ARK Group</title>
        <meta name="description" content="Oops! The page you're looking for could not be found. Return to the home page of ARK Group of Companies." />
      </Helmet>
      <h1 className="text-6xl md:text-8xl font-bold text-RedLight">404</h1>
      <p className="text-2xl md:text-3xl my-8">Oops! Page not found.</p>
      <Link to="/" className="text-RedLight hover:underline">Go to Home Page</Link>
    </div>
  );
};

export default Error;
