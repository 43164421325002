import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion'; // Import motion from Framer Motion
import homeIllustration from '../assets/images/homeIllus.png';
import companyImage from '../assets/images/building - company.png';
import pakistanFlag from '../assets/images/pakistanFlag.png';
import chinaFlag from '../assets/images/chinaFlag.png';
import uaeFlag from '../assets/images/uaeFlag.png';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const Home = () => {
  const [headingText, setHeadingText] = useState('');
  const [typing, setTyping] = useState(true);
  const textToType = 'ARK';
  const circleRefs = useRef([]);
  const [counters, setCounters] = useState([0, 0, 0]); // Initialize counters for each circle

  // Typing animation effect
  useEffect(() => {
    let index = 0;
    let timeoutId = null;

    const typeText = () => {
      if (index < textToType.length) {
        setHeadingText(textToType.substring(0, index + 1));
        index++;
        timeoutId = setTimeout(typeText, 100); // type 1 character every 100ms
      } else {
        setTyping(false);
        timeoutId = setTimeout(removeText, 2000); // wait 2 seconds before removing text
      }
    };

    const removeText = () => {
      if (index > 0) {
        setHeadingText(textToType.substring(0, index - 1));
        index--;
        timeoutId = setTimeout(removeText, 50); // remove 1 character every 50ms
      } else {
        setTyping(true);
        timeoutId = setTimeout(typeText, 2000); // wait 2 seconds before typing again
      }
    };

    typeText();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [textToType]);

  // Count animation effect
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            startCountAnimation(index);
          }
        });
      },
      { threshold: 0.5 } // Adjust threshold as needed
    );

    circleRefs.current.forEach((ref) => ref && observer.observe(ref));

    return () => {
      circleRefs.current.forEach((ref) => ref && observer.unobserve(ref));
    };
  }, []);

  // Function to animate counting up for each circle
  const startCountAnimation = (index) => {
    const targetCount = [15, 100, 2500]; // Your original counts for each circle
    const duration = 2000; // Animation duration in milliseconds
    const step = 50; // Time interval between each count update

    const start = counters[index];
    const end = targetCount[index];
    const totalSteps = duration / step;
    const increment = (end - start) / totalSteps;

    let currentCount = start;

    const intervalId = setInterval(() => {
      currentCount += increment;
      setCounters((prevCounters) => {
        const newCounters = [...prevCounters];
        newCounters[index] = Math.ceil(currentCount); // Update the counter
        return newCounters;
      });

      if (currentCount >= end) {
        clearInterval(intervalId);
      }
    }, step);
  };

  const companies = [
    {
        "name": "ARK Motors",
        "description": "ARK Motors is a leading automobile import and distribution company with an extensive network of suppliers in the UK, Japan, China, and the UAE. We specialize in providing high-quality vehicles to meet diverse customer needs.",
        "link": "/about/ark-motors"
    },
    {
        "name": "ARK Steel",
        "description": "ARK steel manufactures and supplies high-quality steel and iron pipes globally. Our products are known for their durability and reliability, catering to a wide range of industrial applications around the world.",
        "link": "/about/ark-steel"
    },
    {
        "name": "ARK Real Estate and Developers (Private) Limited",
        "description": "ARK Real Estate and Developers excels in construction, real estate, and development projects. With a history of multiple successful projects, including Faizan Real Estate and Developers, we are committed to delivering excellence and innovation in every venture.",
        "link": "/about/ark-real-estate-and-developers"
    },
    {
        "name": "Tianjin Faizan Trade Co",
        "description": "Tianjin Faizan Trade Co is a premier distributor of galvanized coils, color-coated coils (PPGI), and HR coils. With a state-of-the-art distribution network in China and a robust global outreach, we are dedicated to providing high-quality products to our clients worldwide.",
        "link": "/about/tianjin-feizan-trading-co"
    },
    {
        "name": "Faizan Real Estate and Developers",
        "description": "Faizan Real Estate and Developers is a leading name in premier real estate development and construction. We pride ourselves on delivering top-notch residential and commercial projects that meet the highest standards of quality and innovation.",
        "link": "/about/faizan-real-estate-and-developers"
    }
]

  return (
    <div className='mt-[10%]'>
      <Helmet>
        <title>ARK Group of Companies</title>
        <meta name="description" content="Explore ARK Group of Companies, a diversified conglomerate shaping the future through innovation in real estate, metallurgy, and transport. Discover our mission, vision, and global dominance in Pakistan, China, and the UAE." />
      </Helmet>
      <div className="container mx-auto px-2">
        <motion.div initial={{ opacity: 0, x: -50 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }} className="flex flex-col sm:flex-row justify-between items-center">
          <div className="sm:w-1/2 w-full leading-8 mb-4 sm:mb-0">
            <h1 className='sm:text-5xl text-3xl font-[700] text-WhiteLight'>
              <span className='text-RedLight text-5xl'>{headingText} </span>Group <br />
              of Companies,
            </h1>
            <p className='sm:text-xl text-lg text-WhiteLight'>
              a diversified conglomerate shaping the future through innovation and excellence.
            </p>
              <br />
            <p className='sm:text-xl text-lg text-WhiteLight text-justify'>
            We began our journey with a singular focus: steel distribution. From these solid foundations, we've forged an expansive legacy that now spans multiple industries. What started as a steel distribution enterprise has evolved into a dynamic conglomerate, excelling in real estate, metallurgy, and transport.
            </p>
              <br />
            <p className='sm:text-xl text-lg text-WhiteLight text-justify'>
            Our growth is a testament to our commitment to innovation and excellence. Today, ARK Group stands as a beacon of progress, driving transformation across various sectors. Whether it's developing cutting-edge real estate projects, revolutionizing metallurgical processes, or enhancing transportation networks, our diversified portfolio reflects our vision of a future where our impact resonates globally.
            </p>

            <p className='sm:text-xl text-lg text-WhiteLight mt-6'>
              Serving in Various Fields including
              <ul className='ml-10 list-disc text-RedLight'>
                <Link to='/about/ark-real-estate-and-developers'><li>Real Estate</li></Link>
                <Link to='/about/ark-steel'><li>Metallurgy</li></Link>
                <Link to='about/ark-motors'><li>Transport</li></Link>
              </ul>
            </p>
          </div>
          <motion.div initial={{ opacity: 0, x: 50 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }} className="sm:w-1/2 w-full">
            <img src={homeIllustration} className='w-[85%] sm:mr-0 mx-auto sm:w-[70%]' alt="Home Illustration" />
          </motion.div>
        </motion.div>

        <div className="flex flex-col sm:flex-row justify-between items-center mt-10 sm:mt-20 sm:gap-0 gap-4">
          <div className="sm:w-1/2 w-full">
            <div className="sm:w-[50%] w-full mx-auto bg-WhiteLight px-6 py-4 rounded-lg">
              <h2 className='sm:text-3xl text-xl text-center font-[600] text-RedLight'>Our Mission</h2>
              <p className='text-justify sm:text-xl text-lg text-primary font-[200]'>Our mission is to drive progress through innovation, delivering superior value in every sector we operate. We aim to enrich lives and create sustainable solutions for future generations.</p>
            </div>
          </div>
          <div className="sm:w-1/2 w-full">
            <div className="sm:w-[50%] w-full mx-auto bg-WhiteLight px-6 py-4 rounded-lg">
              <h2 className='sm:text-3xl text-xl text-center font-[600] text-RedLight'>Our Vision</h2>
              <p className='text-justify sm:text-xl text-lg text-primary font-[200]'>Our vision is to be a global leader, recognized for our commitment to excellence and innovation. We strive to foster a better future by continuously improving and adapting to the changing world.</p>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row justify-between items-center mt-10 sm:mt-20 sm:gap-0 gap-4">
          <div className="sm:w-1/3 w-full text-center">
            <motion.div initial={{ opacity: 0, x: -50 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}  className="border-4 border-WhiteLight  w-[170px] h-[170px] flex items-center justify-center rounded-full mx-auto transition-colors duration-500">
              <h3 className='sm:text-3xl text-xl font-[600] text-RedLight text-center'>15+<br /><span className='text-GrayDark'>Years</span></h3>
            </motion.div>
          </div>
          <div className="sm:w-1/3 w-full text-center">
            <motion.div initial={{ opacity: 0, x: 50 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}  className="border-4 border-WhiteLight  w-[170px] h-[170px] flex items-center justify-center rounded-full mx-auto transition-colors duration-500">
              <h3 className='sm:text-3xl text-xl font-[600] text-RedLight text-center'>100+<br /><span className='text-GrayDark'>Clients</span></h3>
            </motion.div>
          </div>
          <div className="sm:w-1/3 w-full text-center">
            <motion.div initial={{ opacity: 0, x: -50 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}  className="border-4 border-WhiteLight  w-[170px] h-[170px] flex items-center justify-center rounded-full mx-auto transition-colors duration-500">
              <h3 className='sm:text-3xl text-xl font-[600] text-RedLight text-center'>2500+<br /><span className='text-GrayDark'>Projects</span></h3>
            </motion.div>
          </div>
        </div>

        <div className="sm:mt-20 mt-10">
          <div className="flex flex-col sm:flex-row justify-between items-center mt-5 sm:gap-0 gap-4">
            <motion.div initial={{ opacity: 0, x: -50 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }} className="sm:w-2/3 w-full">
              <h2 className="sm:text-4xl text-2xl my-4 font-[600] text-RedLight ">
                <span className='text-WhiteLight'>Our</span> Companies
              </h2>
            </motion.div>
            <motion.div initial={{ opacity: 0, x: 50 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }} className="sm:w-1/3 w-full">
              <img src={companyImage} className='w-[85%] hidden sm:block sm:mr-0 mx-auto sm:w-[50%]' alt="Company Building" />
            </motion.div>
          </div>

          {/* Companies Grid */}
          <div className="grid sm:grid-cols-2 gap-4 mt-4">
            {companies.map((company, index) => (
              <Link to={company.link}>
              <motion.div 
                key={index}
                initial={{ opacity: 0, y: 50 }} 
                animate={{ opacity: 1, y: 0 }} 
                transition={{ duration: 1, delay: index * 0.2 }}
                className="bg-WhiteLight rounded-lg p-4 shadow-md"
              >
                <h3 className="text-xl font-semibold text-RedLight mb-2">{company.name}</h3>
                <p className="text-primary">{company.description}</p>
                {/* <Link 
                  
                  className="text-RedLight hover:underline mt-2 inline-block"
                >
                  Learn More
                </Link> */}
              </motion.div>
              </Link>
            ))}
          </div>
        </div>

        <div className="sm:mt-20 mt-10">
          <motion.h2 initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }} className="sm:text-4xl text-2xl my-4 font-[600] text-RedLight "><span className='text-WhiteLight'>Global</span> Dominance</motion.h2>
          <motion.p initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }} className='sm:text-xl text-lg text-WhiteLight mt-6  text-justify'>
            As a prominent conglomerate operating across Pakistan, China, and the UAE, we exemplify global dominance through strategic investments and sustainable growth initiatives. Our presence spans key sectors including real estate, metallurgy, and transport, where we continually innovate and drive progress. With a commitment to excellence and a vision to lead globally, we harness local expertise and international partnerships to deliver superior value and shape the future landscape of industries we serve.
          </motion.p>
        </div>
        <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }} className="flex justify-center items-center gap-8 px-[10%] mt-6">
          <img className='w-1/3' src={pakistanFlag} alt="" />
          <img className='w-1/3' src={chinaFlag} alt="" />
          <img className='w-1/3' src={uaeFlag} alt="" />
        </motion.div>
      </div>
    </div>
  );
};

export default Home;
