import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import {useEffect, useState} from 'react'
import Aos from 'aos'
import Nav from './components/Nav';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Blog from './components/Blog';
import Career from './components/Career';
import Contact from './components/Contact';
import Error from './components/Error';
import Loader from './components/Loader';
import Fread from './components/Fread';
import ArkRealEstate from './components/ArkRealEstate';
import ArkMotors from './components/ArkMotors';
import ArkSteel from './components/ArkSteel';
// import ArkElectrodes from './components/ArkElectrodes';
import TainjinFaizan from './components/TainjinFaizan';
// import FaizanArhat from './components/FaizanArhat';


function App() {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Aos.init();

    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);
  
  return (
    <div className="">
      <BrowserRouter>

      {isLoading ? (
          <Loader />
        ) : (
      <><Nav/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/about/faizan-real-estate-and-developers" element={<Fread/>} />
        <Route path="/about/ark-real-estate-and-developers" element={<ArkRealEstate/>} />
        <Route path="/about/ark-motors" element={<ArkMotors/>} />
        <Route path="/about/ark-steel" element={<ArkSteel/>} />
        {/* <Route path="/about/ark-electodes" element={<ArkElectrodes/>} /> */}
        <Route path="/about/tianjin-feizan-trading-co" element={<TainjinFaizan/>} />
        {/* <Route path="/about/faizan-arhat" element={<FaizanArhat/>} /> */}
        <Route path="/blog" element={<Blog/>} />
        <Route path="/career" element={<Career/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="*" element={<Error/>} />
      </Routes>
      <Footer/></>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
