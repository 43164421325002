import React from 'react';
import CEOImage from '../assets/images/ceo-profile.png';
import { FaLinkedin } from 'react-icons/fa';
import aboutUsIllustration from '../assets/images/about-us.png';
import { Helmet } from 'react-helmet';

const About = () => {
  return (
    <div className="mt-[10%]">
      <Helmet>
        <title>About Us - ARK Group of Companies</title>
        <meta name="description" content="Learn about ARK Group of Companies, where innovation meets excellence. Explore our journey, leadership, and commitment to excellence in sectors like Real Estate, Metallurgy, and more." />
      </Helmet>

      <div className="container mx-auto px-2">
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <div className="sm:w-1/2 w-full leading-8 mb-4 sm:mb-0">
            <h1 className='sm:text-5xl text-3xl font-[700] text-WhiteLight'>
              <span className='text-RedLight'>About</span> Us
            </h1>
            <p className='sm:text-xl text-lg text-WhiteLight mt-6'>
              Welcome to ARK Group of Companies, where innovation meets excellence. Our journey began with a vision to excel in multiple sectors, leveraging our expertise in Real Estate, Metallurgy, and beyond. At ARK Group, we are committed to delivering unparalleled services and products that redefine industry standards.
            </p>
            <p className='sm:text-xl text-lg text-WhiteLight mt-6'>
              Our Companies include ARK Motors, specializing in cutting-edge automotive solutions, ARK Pipes, leaders in durable pipeline systems, and ARK Electrodes, providing high-quality welding solutions. Faizan Real Estate and Developers, a subsidiary, focuses on creating sustainable living spaces and commercial developments that enhance communities.
            </p>
            <p className='sm:text-xl text-lg text-WhiteLight mt-6'>
              Serving diverse sectors, including Real Estate and Metallurgy, ARK Group prides itself on innovation, integrity, and customer satisfaction. We continue to grow and adapt, driven by our passion for excellence and our commitment to delivering exceptional value to our clients and partners.
            </p>
          </div>
          <div className="sm:w-1/2 w-full">
            <img src={aboutUsIllustration} className='w-[85%] sm:mr-0 mx-auto sm:w-[50%]' alt="Career Illustration" />
          </div>
        </div>

        <div className="flex flex-col-reverse sm:flex-row items-center mt-10 sm:mt-20">
          <div className="sm:w-1/2 w-full mb-4 sm:mb-0">
            <img src={CEOImage} alt="CEO Umair Barkat Ullah" className="w-[50%] block rounded-full mx-auto mb-4 " />
          </div>
          <div className="sm:w-1/2 w-full leading-8">
            <h2 className="text-2xl font-bold text-RedLight mb-2">Our CEO: <span className='text-WhiteLight'>Umair Barkat Ullah</span></h2>
            <p className="text-GrayDark mb-4">
              Meet Umair Barkat Ullah, visionary leader and driving force behind ARK Group of Companies. With a rich background in entrepreneurship and a passion for innovation, Umair has steered our company towards continuous growth and success. Connect with Umair on LinkedIn to learn more about his journey and vision for the future.
            </p>
            <a 
              href="https://www.linkedin.com/in/umair-barkat-ullah-a14837302/" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-RedLight w-fit hover:text-GrayDark flex items-center"
            >
              <FaLinkedin className="mr-2" />
              LinkedIn Profile
            </a>
          </div>
        </div> 
      </div>
    </div>
  );
};

export default About;
