import React, { useState } from 'react';
import logo from '../assets/images/ARK Motors logo.png';
import car1 from '../assets/images/car 1.jpg';
import car2 from '../assets/images/car 2.jpg';
import car3 from '../assets/images/car 3.jpg';
import { Helmet } from 'react-helmet';

const ArkMotors = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImg, setCurrentImg] = useState('');

  const openModal = (imgSrc) => {
    setCurrentImg(imgSrc);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setCurrentImg('');
  };

  return (
    <div className="mt-[10%]">
      <Helmet>
        <title>ARK Motors - Premier Car Importer and Distributor</title>
        <meta name="description" content="ARK Motors specializes in importing high-quality cars from Japan and selling them in Pakistan. Learn more about our mission, vision, and the services we offer." />
      </Helmet>
      <div className="container mx-auto px-2 min-h-screen">
        <div className="flex flex-col lg:flex-row justify-between items-center">
          <div className="lg:w-1/2 w-full flex flex-col justify-center leading-8 lg:order-1 order-2">
            {/* First Paragraph */}
            <div className="mb-4 lg:mb-0">
              <h1 className='text-3xl lg:text-5xl font-[700] text-WhiteLight'>
                <span className='text-RedLight'>ARK Motors</span>
              </h1>
              <p className='text-lg lg:text-xl text-WhiteLight mt-6'>
                ARK Motors is a premier car importer specializing in sourcing high-quality vehicles from Japan and selling them in Pakistan. Our mission is to provide top-notch cars that meet diverse customer needs and exceed expectations.
              </p>
            </div>
          </div>

          {/* Logo Section */}
          <div className="lg:w-1/2 w-full flex justify-center items-center lg:pl-8 lg:order-2 order-1 mb-4 lg:mb-0">
            <img src={logo} alt="ARK Motors Logo" className="w-full lg:w-1/2 h-auto sm:block hidden" />
          </div>
        </div>

        <div className="mt-4">
          <h2 className="text-xl lg:text-2xl font-semibold text-RedLight">Mission</h2>
          <p className='text-lg lg:text-xl text-WhiteLight mt-2'>
            Our mission at ARK Motors is to deliver high-quality cars imported from Japan to the Pakistani market. We aim to offer a seamless purchasing experience, connecting customers with the vehicles they desire, backed by our commitment to excellence and reliability.
          </p>

          <h2 className="text-xl lg:text-2xl font-semibold text-RedLight mt-6">Vision</h2>
          <p className='text-lg lg:text-xl text-WhiteLight mt-2'>
            We envision ARK Motors as a leading car importer in Pakistan, renowned for our commitment to quality and customer satisfaction. Our goal is to expand our presence, offering a diverse range of Japanese cars and exceptional service, while continuously innovating to meet our customers' evolving needs.
          </p>

          <h2 className="text-xl lg:text-2xl font-semibold text-RedLight mt-6">Our Network</h2>
          <p className='text-lg lg:text-xl text-WhiteLight mt-2'>
            With strong connections to reputable suppliers in Japan, ARK Motors ensures a steady supply of high-quality vehicles tailored to the Pakistani market. Our international partnerships allow us to offer competitive pricing, efficient delivery, and a diverse selection of car models and brands.
          </p>

                    {/* Car Images Section */}
                    <div className="flex flex-col lg:flex-row justify-center items-center gap-8 px-[10%] mt-6 mb-6">
            <img className='w-full lg:w-1/3' src={car1} alt="Car 1" />
            <img className='w-full lg:w-1/3' src={car2} alt="Car 2" />
            <img className='w-full lg:w-1/3' src={car3} alt="Car 3" />
          </div>
          
          <h2 className="text-xl lg:text-2xl font-semibold text-RedLight mt-6">Customer Focus</h2>
          <p className='text-lg lg:text-xl text-WhiteLight mt-2'>
            At ARK Motors, we place our customers at the core of our operations. We strive to offer personalized service, understanding each customer's specific needs, and providing solutions that go above and beyond their expectations. Our dedication to customer satisfaction drives us to continuously enhance our services and build lasting relationships.
          </p>


        </div>
      </div>

      {/* Modal */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-75">
          <div className="relative">
            <img src={currentImg} alt="Expanded View" className="max-w-full max-h-screen" />
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 text-white text-2xl"
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ArkMotors;
