import React, { useRef, useState, useEffect } from "react";
import logo from "../assets/images/logo.png";
import { SlMenu, SlClose } from "react-icons/sl";
import { NavLink } from "react-router-dom";
import { MdOutlineSubdirectoryArrowRight } from "react-icons/md";

const Nav = () => {
  const headerRef = useRef(null);
  const menuRef = useRef(null);

  const toggleMenu = () => menuRef.current.classList.toggle("show_menu");
  return (
    <header ref={headerRef} className="w-full h-[80px] flex items-center">
      <div className="container mx-auto">
        <div className="flex items-center justify-between sm:mt-[5%] px-2">
          <a href="/" className="cursor-pointer w-fit">
            <img className="lg:w-[15%] w-[70px] " src={logo} alt="logo" />
          </a>
          <div className="flex items-center gap-4">
            <div className="menu" ref={menuRef} onClick={toggleMenu}>
              <ul className="flex items-center gap-10 ml-auto">
                <li className="sm:border-0 border-y-2 ">
                  <NavLink className="sm:text-WhiteLight text-RedLight font-[500] sm:font-[400] text-[1.25rem] sm:text-[1.55rem] transition duration-200 hover:border-y border-RedLight  flex justify-center items-center gap-2" to="/"> <span className="sm:hidden"><MdOutlineSubdirectoryArrowRight /></span> Home</NavLink>
                </li>
                <li className="sm:border-0 border-y-2 ">
                  <NavLink className="sm:text-WhiteLight text-RedLight font-[500] sm:font-[400] text-[1.25rem] sm:text-[1.55rem] transition duration-200 hover:border-y border-RedLight  flex justify-center items-center gap-2" to="/about"> <span className="sm:hidden"><MdOutlineSubdirectoryArrowRight /></span> About</NavLink>
                </li>
                
                <li className="sm:border-0 border-y-2 ">
                  <NavLink className="sm:text-WhiteLight text-RedLight font-[500] sm:font-[400] text-[1.25rem] sm:text-[1.55rem] transition duration-200 hover:border-y border-RedLight  flex justify-center items-center gap-2" to="/blog"> <span className="sm:hidden"><MdOutlineSubdirectoryArrowRight /></span> Blog</NavLink>
                </li>
                <li className="sm:border-0 border-y-2 ">
                  <NavLink className="sm:text-WhiteLight text-RedLight font-[500] sm:font-[400] text-[1.25rem] sm:text-[1.55rem] transition duration-200 hover:border-y border-RedLight  flex justify-center items-center gap-2" to="/career"> <span className="sm:hidden"><MdOutlineSubdirectoryArrowRight /></span> Careers</NavLink>
                </li>
                {/* <li className="sm:border-0 border-y-2 ">
                  <NavLink className="sm:text-WhiteLight text-RedLight font-[600]5tex sm:font-[400]t-[1rem].25 sm:text-[1.75re5] transition duration-200 hover:border-y border-RedLight  flex justify-center items-center gap-2" to="/uses">Uses</NavLink>
                </li> */}
                <li className="sm:border-0 border-y-2 ">
                  <NavLink className="sm:text-WhiteLight text-RedLight font-[500] sm:font-[400] text-[1.25rem] sm:text-[1.55rem] transition duration-200 hover:border-y border-RedLight  flex justify-center items-center gap-2" to="/contact"> <span className="sm:hidden"><MdOutlineSubdirectoryArrowRight /></span> Contact</NavLink>
                </li>

              </ul>
            </div>
            <span onClick={toggleMenu} className="lg:hidden md:hidden flex  cursor-pointer">
              <SlMenu className="w-[35px] h-[35px] text-WhiteLight" />
            </span>
          </div>
        </div>
      </div>

    </header>
  )
}

export default Nav
