import React from 'react';
import logo from '../assets/images/Ark Real Estate and Develoers Logo.png';
import rudaLogo from '../assets/images/Ruda logo.png';
import chaharbaghLogo from '../assets/images/CHAHAR BAGH Logo.png';
import chaharbaghEnclaveLogo from '../assets/images/CHAHAR BAGH ENCLAve.png';
import { Helmet } from 'react-helmet';

const ArkRealEstate = () => {
  return (
    <div className="mt-[10%]">
      <Helmet>
        <title>ARK Real Estate and Developers - Local Marketing Partner for RUDA</title>
        <meta name="description" content="ARK Real Estate and Developers, as a Local Marketing Partner with RUDA, specializes exclusively in RUDA's projects, including Chahar Bagh and Chahar Bagh Enclave in Lahore." />
      </Helmet>
      <div className="container mx-auto px-2 min-h-screen">
        <div className="flex flex-col lg:flex-row justify-between items-center">
          <div className="lg:w-1/2 w-full flex flex-col justify-center leading-8 lg:order-1 order-2">
            {/* First Paragraph */}
            <div className="mb-4 lg:mb-0">
              <h1 className='text-3xl lg:text-5xl font-[700] text-WhiteLight'>
                <span className='text-RedLight'>ARK Real Estate and Developers</span>
              </h1>
              <p className='text-lg lg:text-xl text-WhiteLight mt-6'>
                ARK Real Estate and Developers is a proud Local Marketing Partner with the Ravi Urban Development Authority (RUDA). We specialize exclusively in RUDA-approved projects, including Chahar Bagh and Chahar Bagh Enclave in Lahore. Our mission is to empower clients with the knowledge and resources necessary to achieve their property goals within these prestigious developments.
              </p>
            </div>
          </div>

          {/* Logo Section */}
          <div className="lg:w-1/2 w-full flex justify-center items-center lg:pl-8 lg:order-2 order-1 mb-4 lg:mb-0">
            <img src={logo} alt="ARK Real Estate and Developers Logo" className="w-full lg:w-1/2 h-auto sm:block hidden" />
          </div>
        </div>

        <div className="mt-4">
          <h2 className="text-xl lg:text-2xl font-semibold text-RedLight">Mission</h2>
          <p className='text-lg lg:text-xl text-WhiteLight mt-2'>
            Our mission is to provide a seamless experience for the sale and acquisition of RUDA properties, with a dedicated focus on Chahar Bagh and Chahar Bagh Enclave. We offer expert investment advisory and financing assistance, ensuring that our clients secure their dream properties with confidence.
          </p>

          <h2 className="text-xl lg:text-2xl font-semibold text-RedLight mt-6">Vision</h2>
          <p className='text-lg lg:text-xl text-WhiteLight mt-2'>
            We envision a future where our clients leverage RUDA properties, specifically Chahar Bagh and Chahar Bagh Enclave, to build a secure and prosperous future. By providing comprehensive property management services, we aim to maximize the return on investment and guide clients through every step of the property ownership process.
          </p>

          <h2 className="text-xl lg:text-2xl font-semibold text-RedLight mt-6">Projects and Partners</h2>
          <div className="flex flex-col lg:flex-row justify-center items-center gap-8 mt-4 w-full lg:w-[60%] mx-auto">
            <img className='w-full lg:w-1/3' src={chaharbaghLogo} alt="Chahar Bagh Logo" />
            <img className='w-full lg:w-1/3' src={rudaLogo} alt="RUDA Logo" />
            <img className='w-full lg:w-1/3' src={chaharbaghEnclaveLogo} alt="Chahar Bagh Enclave Logo" />
          </div>

          <h2 className="text-xl lg:text-2xl font-semibold text-RedLight mt-6">Chahar Bagh</h2>
          <p className='text-lg lg:text-xl text-WhiteLight mt-2'>
            Chahar Bagh is an ambitious urban development project designed to cater to a wide range of lifestyles and preferences. Located in a prime area of Lahore, Chahar Bagh integrates modern architectural aesthetics with sustainable urban planning. The development features state-of-the-art amenities including expansive green spaces, recreational facilities, and a mix of residential options such as high-end apartments, villas, and townhouses. It is strategically positioned to offer easy access to major commercial hubs and educational institutions.
          </p>

          <h2 className="text-xl lg:text-2xl font-semibold text-RedLight mt-6">Chahar Bagh Enclave</h2>
          <p className='text-lg lg:text-xl text-WhiteLight mt-2'>
            Chahar Bagh Enclave is a premium extension within the Chahar Bagh development, offering an elevated living experience. This exclusive enclave features meticulously planned residential plots and high-end properties designed to meet the highest standards of luxury and comfort. The enclave boasts enhanced security measures, private parks, and community centers, creating a serene and sophisticated living environment. Residents benefit from proximity to elite schools, healthcare facilities, and shopping areas, making it an ideal choice for families and professionals alike.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ArkRealEstate;
