import React, { useState } from 'react';
import logo from '../assets/images/ARK STEEL logo.png';
import gpCoil from '../assets/images/gp coils.jpg'
import gpCoilM from '../assets/images/gp coils m.jpg'
import ironRods from '../assets/images/iron rods.jpg'
import { Helmet } from 'react-helmet';

const ArkSteel = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImg, setCurrentImg] = useState('');

  const openModal = (imgSrc) => {
    setCurrentImg(imgSrc);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setCurrentImg('');
  };

  return (
    <div className="mt-[10%]">
      <Helmet>
        <title>ARK Steel - Global Supplier of High-Quality Steel and Iron Pipes</title>
        <meta name="description" content="ARK Steel manufactures and supplies high-quality steel and iron pipes globally, known for their durability and reliability. Discover our mission, vision, and product offerings." />
      </Helmet>
      <div className="container mx-auto px-2 min-h-screen">
        <div className="flex flex-col lg:flex-row justify-between items-center">
          <div className="lg:w-1/2 w-full flex flex-col justify-center leading-8 lg:order-1 order-2">
            {/* First Paragraph */}
            <div className="mb-4 lg:mb-0">
              <h1 className='text-3xl lg:text-5xl font-bold text-WhiteLight'>
                <span className='text-RedLight'>ARK Steel</span>
              </h1>
              <p className='text-lg lg:text-xl text-WhiteLight mt-6'>
                Starting with steel distribution, ARK Steel has expanded into multiple sectors, becoming a global leader in manufacturing and supplying high-quality steel and iron pipes. Our products are renowned for their durability and reliability across various industrial applications.
              </p>
            </div>
          </div>

          {/* Logo Section */}
          <div className="lg:w-1/2 w-full flex justify-center items-center lg:pl-8 lg:order-2 order-1 mb-4 lg:mb-0">
            <img src={logo} alt="ARK Steel Logo" className="w-full lg:w-1/2 h-auto sm:block hidden" />
          </div>
        </div>


        <div className="mt-4">
          <h2 className="text-xl lg:text-2xl font-semibold text-RedLight">Mission</h2>
          <p className='text-lg lg:text-xl text-WhiteLight mt-2'>
            Our mission at ARK Steel is to deliver high-quality steel and iron products that meet the rigorous demands of various industrial applications. We are dedicated to maintaining the highest standards of durability and reliability in every product we manufacture and supply.
          </p>

          <h2 className="text-xl lg:text-2xl font-semibold text-RedLight mt-6">Vision</h2>
          <p className='text-lg lg:text-xl text-WhiteLight mt-2'>
            ARK Steel envisions being a global leader in the steel industry, known for innovation, quality, and customer satisfaction. Our vision is to continually expand our product offerings, meet the evolving needs of the industrial sector, and set new benchmarks in the global market.
          </p>

          {/* Product Offerings */}
          <h2 className="text-xl lg:text-2xl font-semibold text-RedLight mt-6">Product Offerings</h2>
          <ul className='list-disc list-inside text-lg lg:text-xl text-WhiteLight mt-2 pl-6'>
            <li className='text-WhiteLight'>GP Coils</li>
            <li className='text-WhiteLight'>HR Coils</li>
            <li className='text-WhiteLight'>HR Plates</li>
            <li className='text-WhiteLight'>Iron Rods</li>
            <li className='text-WhiteLight'>Iron Pipes</li>
          </ul>

          <div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }} className="flex flex-col lg:flex-row justify-center items-center gap-8 px-[10%] mt-6 mb-6">
  <img className='w-full lg:w-1/3' src={gpCoil} alt="" />
  <img className='w-full lg:w-1/3' src={gpCoilM} alt="" />
  <img className='w-full lg:w-1/3' src={ironRods} alt="" />
</div>


          <h2 className="text-xl lg:text-2xl font-semibold text-RedLight mt-6">Global Reach</h2>
          <p className='text-lg lg:text-xl text-WhiteLight mt-2'>
            With a global distribution network, ARK Steel ensures timely delivery of our products to clients across the world. Our commitment to quality and customer satisfaction has earned us a strong reputation in international markets.
          </p>
        </div>
      </div>

      {/* Modal */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-75">
          <div className="relative">
            <img src={currentImg} alt="Expanded View" className="max-w-full max-h-screen" />
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 text-white text-2xl"
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ArkSteel;
