import React from 'react';
import careerImage from '../assets/images/career.png';
import { Helmet } from 'react-helmet';

const Career = () => {
  return (
    <div className="mt-[10%]">

<Helmet>
        <title>Career Opportunities at ARK Group - Join Our Team</title>
        <meta name="description" content="Explore exciting career opportunities with ARK Group of Companies. We are hiring in fields like Real Estate, Metallurgy, and Transport. Apply now!" />
      </Helmet>
      <div className="container mx-auto px-2 min-h-screen">
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <div className="sm:w-1/2 w-full leading-8 mb-4 sm:mb-0">
            <h1 className='sm:text-5xl text-3xl font-[700] text-WhiteLight'>
              <span className='text-RedLight'>Join Our</span> Team
            </h1>
            <p className='sm:text-xl text-lg text-WhiteLight mt-6'>
              Explore exciting career opportunities with ARK Group of Companies.
            </p>
            <p className='sm:text-xl text-lg text-WhiteLight mt-6'>
              We are looking for talented individuals to join our diverse team in various fields including:
              <ul className='ml-10 list-disc text-RedLight'>
                <li>Real Estate</li>
                <li>Metallurgy</li>
                <li>Transport</li>
              </ul>
            </p>
            <p className='sm:text-xl text-lg text-WhiteLight mt-6'>
              To apply, please send your resume to <a className='text-RedLight hover:underline' href='mailto:info@arkgroup.pk'>info@arkgroup.pk</a>.
            </p>
          </div>
          <div className="sm:w-1/2 w-full">
            <img src={careerImage} className='w-[85%] sm:mr-0 mx-auto sm:w-[50%]' alt="Career Illustration" />
          </div>
        </div>

        <div className="flex flex-col sm:flex-row justify-between items-center mt-10 sm:mt-20 sm:gap-0 gap-4">
          <div className="sm:w-1/2 w-full">
            <div className="sm:w-[50%] w-full mx-auto bg-WhiteLight h-[15%] px-6 py-4 rounded-lg">
              <h2 className='sm:text-3xl text-xl text-center font-[600] text-RedLight'>Why Join Us?</h2>
              <p className='text-justify sm:text-xl text-lg text-primary font-[200]'>At ARK Group of Companies, we believe in fostering a culture of growth, innovation, and excellence. Join us and be part of a team that is shaping the future through sustainable solutions and continuous improvement.</p>
            </div>
          </div>
          <div className="sm:w-1/2 w-full">
            <div className="sm:w-[50%] w-full mx-auto bg-WhiteLight h-[15%] px-6 py-4 rounded-lg">
              <h2 className='sm:text-3xl text-xl text-center font-[600] text-RedLight'>Current Openings</h2>
              <p className='text-justify sm:text-xl text-lg text-primary font-[200]'>Explore our current job openings and find the right opportunity that matches your skills and aspirations. We offer competitive benefits and an inclusive work environment where your contributions are valued.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;
